import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "map", "latitude", "longitude" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    if (!this.latitudeTarget.value == "" && !this.longitudeTarget.value == "") {
      this.map = new google.maps.Map(this.mapTarget, {
        mapId: "8d648ebbd6f8cae5",
        center: new google.maps.LatLng(this.latitudeTarget.value,this.longitudeTarget.value),
        zoom: 17
      })
    } else {
      this.map = new google.maps.Map(this.mapTarget, {
        mapId: "fe1b5c683dd2c449",
        center: new google.maps.LatLng(37.09024,-95.712891),
        zoom: 4
      })
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChnaged.bind(this))

    if (!this.latitudeTarget.value == "" && !this.longitudeTarget.value == "") {
      this.markerDefault = new google.maps.Marker({
        map: this.map,
        position: ({ lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) }),
        // anchorPoint: new google.maps.Point(0,29)
      })
    } else {
      this.marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0,29)
      })
    }
    
    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0,29)
    })

  }

  async getTimeZone (lat, long) {
//     const APIresponse = await fetch(');
//   // do something with myJson


// APIresponse.then((response) => {
//   const jsonPromise = response.json();
//   jsonPromise.then((data) => {
//     return(data[0]);
//   });
// });
    const fetchPromise =  fetch('https://maps.googleapis.com/maps/api/timezone/json?location=' + lat + ',' + long + '&timestamp=' + Math.round(+new Date()/1000) + '&key=AIzaSyBq4SzO8nIrupQe2mgv5ICyLaPECbwb4ak');

    fetchPromise.then((response) => {
      const jsonPromise = response.json();
      jsonPromise.then((data) => {
        console.log(data.timeZoneId);
        // document.getElementById("select-time-zone").text = data.timeZoneId;
        // $("#select-time-zone").val(data.timeZoneId).change();
        // $("#select-time-zone option[text=" + data.timeZoneId +"]").prop("selected", true);
        // $('select-time-zone').selectize().disable();
        // $("select#select-time-zone option").each(function() { this.selected = (this.text == data.timeZoneId); });
        // this.timezoneTarget.value = data.timeZoneId;
        // $('#select-time-zone').data('selectize').setText(data.timeZoneId);
        // $("#select-time-zone").dropdown('set selected', 100)
        $("#select-time-zone").find("option:contains(" + data.timeZoneId+ ")").attr('selected', 'selected');
        var element = $("#select-time-zone").dropdown('get value');
          $("#select-time-zone").dropdown('set selected', element)

          $("#activity_utc_offset").val( data.rawOffset / 60 / 60)         
      });
    });
  }

  async placeChnaged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert('no details available for input: ${place.name}')
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }
    this.marker.setPosition(place.geometry.location)
    this.markerDefault.setVisible(false)
    this.marker.setVisible(true)

    await this.getTimeZone(parseFloat(place.geometry.location.lat()), parseFloat(place.geometry.location.lng()));

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
