// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../../assets/stylesheets/application'
import 'styles/application'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'controllers'
import "channels"

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import Popper from 'popper.js/dist/umd/popper.js';
window.Popper = Popper;

import '@doabit/semantic-ui-sass'
import 'bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on("click", "tr.clickable-table[data-url]", function (e) {
    var t = (e.currentTarget);
    var url = $(t).attr('data-url')
    if (url != null && e.target.tagName != "BUTTON") {
      window.open(url, "_self")
    }
})

document.addEventListener("turbolinks:load", () => {
  $.ajaxSetup({ cache: true });

  $("#clearFiltersActivity").on("click", () => {
    $("#nsc_reference").val("")
    $("#activity_title").val("")
    $("#status").val("")
    $.ajax({
      url:
        '/activities',
      dataType: 'script',
      method: 'GET',
    });
  })

})



require("trix")
require("@rails/actiontext")